
import { Options, Vue } from "vue-class-component";
import BackgroundImage from "@/components/BackgroundImage.vue";
import Button from "@/components/Button.vue";

@Options({
  components: {
    BackgroundImage,
    Button,
  },
})
export default class NotFound extends Vue {
  private goToHome() {
    this.$router.push("/");
  }
}
